import { Link } from "gatsby"
import React from "react"
import 'twin.macro'
import { Wrapper } from "./PostHeader.styled"


const PostHeader = ({postData, category})=> { 
    const resourcesCategories = ['case studies','infographics','interactives','white papers','video']
    const mainSection = resourcesCategories.indexOf(category.toLowerCase()) !== -1
        ? 'resources'
        : 'news'

    return(
        <Wrapper>
            <p className="category"><Link to={`/${mainSection}/`}>{mainSection.toUpperCase()}</Link> / <span className="orange">{category}</span></p>
            <h1>{postData.title}</h1>
            {/*<p>{postData.date}</p>*/}
        </Wrapper>
    )
}

export default PostHeader