import { useStaticQuery, graphql } from "gatsby"

export const useDefaultPostImage = ()=> {

    return useStaticQuery(graphql`

        query PostImage {

            allWpPage(filter: {title: {eq: "RESOURCES"}}) {
                edges {
                    node {
                        ACF_postTemplateOptions {
                            defaultFeaturedImage {
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData(layout: FULL_WIDTH)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    `)
}



