import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`

    ${tw`bg-white flex flex-col`}

    &.glass-rounded{
        ${tw`bg-glasslighter rounded-b-xl`}
    }
    
    .img-wrapper{
        ${tw`relative overflow-hidden flex h-48 sm:h-64 md:h-80`}
        font-size: 0;

        img{
            object-fit: cover;
            width: 100%;
        }

        background-image: ${({ bgasset }) => `url(${bgasset})`};

        .category-tag{
            ${tw`bg-lightorange text-white uppercase absolute bottom-0 right-0 m-0 text-base font-bold py-1 px-2 text-center`}
            min-width: 50%;
        }
    }

    .text-wrapper{
        ${tw`p-6 flex flex-col justify-start`}
        height: 100%;

        h3{
            ${tw`text-3xl m-0 font-medium mb-10`}
        }

        small.date{
            ${tw`text-lg m-0 mb-4`}
        }

        .excerpt{
            ${tw`text-lg m-0`}
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
                    line-clamp: 3; 
            -webkit-box-orient: vertical;

            @media (min-width: 1024px){
                -webkit-line-clamp: 4; /* number of lines to show */
                line-clamp: 4; 
            }
        }

        a{
            ${tw`font-bold text-lightorange no-underline ml-auto w-max block mt-auto`}
        }
    }
`