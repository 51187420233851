import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout/Layout"
import PostHeader from "../../components/PostTemplate/PostHeader/PostHeader"
import MainContent from "../../components/PostTemplate/MainContent/MainContent"

import { useDefaultPostImage } from "../../hooks/useDefaultPostImage" 
import Seo from "../../components/Seo/Seo"


const PostTemplate = ({ data }) => {

    console.log(data)

    const DefaultImagePostCMS = useDefaultPostImage().allWpPage.edges[0].node.ACF_postTemplateOptions.defaultFeaturedImage;
    const allCategories = [
      'case studies','infographics','interactives','white papers','video', 
      'press releases', 'in the news', 'blog'
    ]
    
    const category = data.post.categories.nodes
      .map(item => item.name)
      .filter(category => allCategories.indexOf(category.toLowerCase()) !== -1)
      .join(' | ')
    
    return(
        <Layout>
            <Seo title={data.post.title} />
            <PostHeader postData={data.post} category={category}/>
            <MainContent
                postFeaturedImage={
                  data.post.featuredImage && data.post.featuredImage.node.localFile.childImageSharp
                    ? data.post.featuredImage.node.localFile.childImageSharp.gatsbyImageData
                    : DefaultImagePostCMS.localFile.childImageSharp.gatsbyImageData
                }
                downloadableFile={data.post.ACF_ArticlePost.downloadableFile}
                embedIntoPost={data.post.ACF_ArticlePost.embedIntoPost}
                content={data.post.content}
                category={category}
                currentURI={data.post.uri}
            />
        </Layout>
    )
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    post: wpPost(id: { eq: $id }) {
      title
      content

      featuredImage {
        node {
            localFile {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
      }

      author {
        node {
          name
        }
      }

      date(formatString: "MM / DD / YYYY")

      categories {
        nodes {
          id
          name
          uri
          slug
        }
      }
      uri

      ACF_ArticlePost {
        downloadableFile {
          localFile {
            publicURL
          }
        }
        embedIntoPost
      }
    }
  }
`
