import { useStaticQuery, graphql } from "gatsby"

export const useAllPostsQuery = ()=> {

    return useStaticQuery(graphql`
    
        query AllPosts {
            allWpPost (sort: {fields: date, order: DESC}) {
                edges {
                    node {
                        title
                        uri
                        date
                        content
                        excerpt
                        categories {
                            nodes {
                                name
                            }
                        }
                        ACF_ArticlePost {
                            downloadableFile {
                                localFile {
                                    publicURL
                                }
                            }
                            externalLink
                        }
                        featuredImage {
                            node {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
}