import React, { useEffect } from "react"
import 'twin.macro'
import { Wrapper } from "./RelatedPosts.styled"
import { useState } from "react"

import { useAllPostsQuery } from "../../../hooks/useAllPostsQuery"
import { useDefaultPostImage } from "../../../hooks/useDefaultPostImage"
import PostTile from "../../Resources/PostTile/PostTile"


const RelatedPosts = ({category, currentURI})=> { 

    const DefaultImagePostCMS = useDefaultPostImage().allWpPage.edges[0].node.ACF_postTemplateOptions.defaultFeaturedImage;

    const [randomPosts, setRandomPosts] = useState([]);

    const allPosts = useAllPostsQuery().allWpPost.edges

    let filteredPosts = allPosts.filter(item => {
        return item.node.uri !== currentURI && item.node.categories.nodes.some(sub => {   
            return sub.name === category
        })
    })

    const getRandom = (arr, n) => {
        let result = new Array(n),
            len = arr.length,
            taken = new Array(len);
        if (n > len) n = len
        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
    }

    useEffect(()=>{
        setRandomPosts(getRandom(filteredPosts, 2))
    }, [])
    
    return(
        <Wrapper>
            <h2>Related Stories</h2>
            
            <div className="posts-grid">
                {randomPosts.map((item, index) => {

                    let content = "...";
                    if(item.node.excerpt)        content = item.node.excerpt.replace(/<[^>]*>/g, '').substr(0, 180) + "..."
                    else if(item.node.content)   content = item.node.content.replace(/<[^>]*>/g, '').substr(0, 180) + "..."
                    
                    const rawDate = new Date(item.node.date);
                    const formattedDate = `${rawDate.getMonth()+1}/${rawDate.getDate()}/${rawDate.getFullYear()}`
                    const featuredImage = item.node.featuredImage ? item.node.featuredImage.node.localFile.publicURL : DefaultImagePostCMS.localFile.publicURL
                    const categories = item.node.categories.nodes.map(item => item.name === 'uncategorized' ? '' : item.name).join(', ')
                    const uri = item.node.uri
                    const title = item.node.title

                    return(
                        <PostTile
                            key={index}
                            featuredImage={featuredImage} 
                            categories={categories} 
                            formattedDate={formattedDate}
                            title={title} 
                            content={content}
                            uri={uri}
                            customClasses={`glass-rounded`}
                        />
                    )
                })}
            </div>
        </Wrapper>
    )
}

export default RelatedPosts