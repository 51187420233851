import tw, { styled } from "twin.macro"
import desktopBg from "../../../images/Post/post-header-desktop.jpg"
import mobileBg from "../../../images/Post/post-header-mobile.jpg"

export const Wrapper = styled.div`

  ${tw`w-full text-darkgray font-medium flex flex-col items-center text-center box-border bg-bonegray bg-no-repeat bg-contain`}
  padding-top: 20vw;
  background-image: ${`url(${mobileBg})`};

  @media (min-width: 641px){
    padding-top: 5vw;
    background-image: ${`url(${desktopBg})`};
  }  

  .category{
      ${tw`uppercase`}

      a{
        ${tw`text-darkgray no-underline hover:text-orange`}
      }

      .orange{
          ${tw`text-lightorange`}
      }
  }

  h1{
      ${tw`font-light text-4xl m-4`}
      max-width: 58rem;
  }
  p{
    ${tw`font-light text-xl m-3`}
  }
`;
