import React from "react"
import 'twin.macro'
import { Wrapper } from "./MainContent.styled"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import RelatedPosts from "../RelatedPosts/RelatedPosts"  


const MainContent = ({postFeaturedImage, downloadableFile, embedIntoPost, content, category, currentURI})=> { 

    return(
        <Wrapper>
            <article>
                <div className="image-social-wrapper">
                    <div className="s9-widget-wrapper"></div>
                    <GatsbyImage className="main-image" image={getImage(postFeaturedImage)} alt=""/>
                </div>

                <div className="text-wrapper">
                    <div dangerouslySetInnerHTML={{__html: content }}></div>

                    {downloadableFile && embedIntoPost 
                        ? <iframe src={downloadableFile.localFile.publicURL} width="100%" height="600"></iframe>
                        : null
                    }

                    {downloadableFile 
                        ? <a className="download-file-link" href={downloadableFile.localFile.publicURL} target="_blank" download>DOWNLOAD FILE</a>
                        : null
                    }
                </div>
        
                <RelatedPosts
                    category={category}
                    currentURI={currentURI}
                />
            </article>
        </Wrapper>
    )
}

export default MainContent