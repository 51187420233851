import React from "react";
import { Wrapper } from "./PostTile.styled";
import { Link } from "gatsby";

const PostTile = ({featuredImage, categories, formattedDate, title, content, uri, externalLink, customClasses})=> {

    return(
        <Wrapper className={`post-tile ${customClasses ? customClasses : ''}`}>
            <div className="img-wrapper" bgasset={featuredImage}>
                <img srcSet={`${featuredImage} 2x`} alt=""/>
                <p className="category-tag">{categories}</p>
            </div>
            <div className="text-wrapper">
                <h3>{title}</h3>
                {/*<small className="date">{formattedDate}</small>*/}
                <div dangerouslySetInnerHTML={{ __html: content }} className="excerpt"></div>
                {externalLink 
                    ? (<a href={externalLink} target="_blank">READ MORE</a>)
                    : (<Link to={`/posts${uri}`}>READ MORE</Link>)
                }
            </div>
        </Wrapper>
    )
}

export default PostTile