import tw, { styled } from "twin.macro"

import fbIcon from "../../../images/social-icons/share-fb.svg"
import twitterIcon from "../../../images/social-icons/share-twitter.svg"
import pinterestIcon from "../../../images/social-icons/share-pinterest.svg"
import emailIcon from "../../../images/social-icons/share-email.svg"

export const Wrapper = styled.div`
    ${tw`w-full text-darkgray flex flex-col  bg-bonegray`}

    article{
        ${tw`max-w-screen-lg w-full mx-auto box-border py-6 px-8 xl:px-0`}

        .image-social-wrapper{
            ${tw`flex flex-col sm:flex-row`}

            .main-image{
                ${tw`w-full`}
            }

            #s9-share-inline{
                ${tw`flex flex-row sm:flex-col items-start mr-4`}

                a{
                    ${tw`bg-transparent m-0 p-0 mb-3 mr-3 sm:mr-0`}
                    transition: 0.2s all;
                    
                    &:hover{
                        transform: scale(1.25);
                    }

                    span{
                        ${tw`hidden`}
                    }

                    &.s9-facebook::after{
                        ${tw`block w-8 h-8 bg-contain bg-no-repeat`}
                        content: " ";
                        background-image: ${`url(${fbIcon})`}
                    }
                    &.s9-twitter::after{
                        ${tw`block w-8 h-8 bg-contain bg-no-repeat`}
                        content: " ";
                        background-image: ${`url(${twitterIcon})`}
                    }
                    &.s9-pinterest::after{
                        ${tw`block w-8 h-8 bg-contain bg-no-repeat`}
                        content: " ";
                        background-image: ${`url(${pinterestIcon})`}
                    }
                    &.s9-email::after{
                        ${tw`block w-8 h-8 bg-contain bg-no-repeat`}
                        content: " ";
                        background-image: ${`url(${emailIcon})`}
                    }

                }

            }

        }

        & > .text-wrapper{
            ${tw`sm:pr-10 sm:pl-20   lg:pr-20 lg:pl-32`}

            a{
                ${tw`text-lightorange`}

                &.download-file-link{
                    ${tw`bg-lightorange hover:bg-orange transition text-white no-underline font-bold px-8 py-2 block w-max ml-auto`}
                }
            }
        }

        iframe{
            border:0;
        }
    }
`;
