import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
    ${tw`
        max-w-screen-xl w-full mx-auto px-4 sm:px-8 xl:px-0 mt-8 sm:mt-0 box-border
        text-darkgray  bg-bonegray
    `}

    h2{
        ${tw`font-light text-4xl text-center mt-10 mb-16`}
    }

    .posts-grid{
        ${tw`grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-10`}
    }
`;
